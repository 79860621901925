import React from 'react';
import Button from '@mui/material/Button';
import styles from './DialogContentStatus.module.scss';
import { DialogContentStatusProps } from './DialogContentStatus.interface';
import { CheckCircleIcon, WarnCircleIcon } from '@theme/icons';

const DialogContentStatus: React.FC<DialogContentStatusProps> = ({
  completedTexts,
  close,
  hideDescription,
  variant = 'check',
}) => {
  return (
    <>
      <div className={styles.accountDialogContentStatus}>
        {variant === 'check' ? <CheckCircleIcon className='icon check' /> : <WarnCircleIcon className='icon warn' />}
        <div className={styles.accountDialogContentStatusTitle}>{completedTexts?.title ?? 'Order Successful'}</div>
        {!hideDescription && (
          <div className={styles.accountDialogContentStatusSubTitle}>
            {completedTexts?.description ?? 'An email was sent to your account'}
          </div>
        )}
      </div>
      {close && (
        <div className={styles.accountDialogContentStatusActions}>
          <Button onClick={close} color='primary' variant='outlined'>
            Close
          </Button>
        </div>
      )}
    </>
  );
};

export default DialogContentStatus;
